.btn-light{
    color: rgba(0, 0, 0, 0.8);
}
.btn{
    font-variant: all-small-caps;
    border-radius: 10px;

    padding-left: 12px;
padding-right: 12px;
transition: .3s;
font-weight: 300;
padding-bottom: 4px;
padding-top: 3px;
}
hr{
    opacity: 0.05;
}

.alert{
backdrop-filter:blur(10px);
}

.alert-danger{
    background: rgba(249, 199, 199, 0.43);
    border-color: rgba(181, 108, 108, 0.5);
}


#root .MuiDataGrid-root .MuiDataGrid-columnHeader:focus,#root .MuiDataGrid-root .MuiDataGrid-cell:focus{
    outline: none !important;
}
.form-control{
    border-radius: 10px;
    font-size: 14px;
    height: 32px;
}

small{
    font-size: 12px;
color: rgba(0,0,0,0.5);
font-weight: 300;
}

.btn-primary {
    color: #f9f2ea;
    background-color:#9e896e;
    border-color: #988160;
}
.btn-primary:hover {
    color: #f9f2ea;
    background-color: #c29b69;
    border-color: #ab7f46;
}
.btn-primary:active {
    color: white;
    background-color: #c2965c;
    border-color: #ab7f46;
}

.btn-danger {
    color: #772828;
background-color: transparent;
border-color: transparent;
}
.bg-danger{
    background-color: rgba(221, 53, 69, 0.14)!important;
    transition: .3s;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  .MuiFilledInput-root textarea,
  .MuiFilledInput-root input,
  .MuiFilledInput-root div,
  .MuiFilledInput-root select{
  font-size: 14px;
line-height: 16px;
  }


  #root a{
    text-decoration: none !important;
  }
  .MuiFilledInput-root.Mui-disabled{
    background-color:rgba(0, 0, 0, 0.02) !important;

  }

  .MuiFilledInput-root.Mui-disabled textarea,
  .MuiFilledInput-root.Mui-disabled input,
  .MuiFilledInput-root.Mui-disabled div,
  .MuiFilledInput-root.Mui-disabled select{
    -webkit-text-fill-color:rgba(0, 0, 0, 0.69) !important;
  }
  
.btn-danger:hover {
    color: #772828;
background-color: rgba(255, 0, 0, 0.1);
border-color: transparent;
    }
    
    .MuiButton-root.MuiButton-textPrimary {
        background-color: rgba(25, 118, 210, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textPrimary:hover {
        background-color: rgba(25, 118, 210, 0.1) !important;
    }

    

    #primaryButton.MuiButton-textSuccess{
        background-color:#4AB276 !important;
        color:white;
    }

    .MuiButton-root.MuiButton-textWarning {
        background-color: rgba(237, 108, 2, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textWarning:hover {
        background-color: rgba(237, 108, 2, 0.1) !important;
    }

    .MuiButton-root.MuiButton-textError {
        background-color: rgba(211, 47, 47, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textError:hover {
        background-color: rgba(211, 47, 47, 0.1) !important;
    }
    .MuiButton-root.MuiButton-textSuccess,
    .MuiIconButton-root.MuiIconButton-colorSuccess {
        background-color: rgba(46, 125, 50, 0.04) !important;
    }
    .MuiButton-root.MuiButton-textSuccess:hover,
    .MuiIconButton-root.MuiIconButton-colorSuccess:hover {
        background-color: rgba(46, 125, 50, 0.1) !important;
    }
    
    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: rgba(249, 249, 253, 0);
        
      }
      
      
      ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        height: 5px;
        background-color:  rgba(190, 190, 190, 0.8);
      }
      ::-webkit-scrollbar-track{
       
        background-color:  rgba(190, 190, 190, 0);
      }
      

      .hiddenScroll::-webkit-scrollbar-thumb{
        background-color:  rgba(190, 190, 190, 0);
        width: 1px;
        height: 1px;;
      }
      .hiddenScroll ::-webkit-scrollbar {
        width: 1px;
        background-color: rgba(249, 249, 253, 0);
      }
  body ul:focus,
  body:focus,
  body div:focus,
  body a:focus,
  body p:focus{
    outline: none !important;
  }

  #primaryButton.Mui-disabled{
    opacity: 0.5;
  }

  #root .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-formControl:before{
    border-bottom: none !important;
  }

  #root .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-formControl{
      border-radius: 5px;
      overflow: hidden;
  }


  #root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root.MuiTab-textColorSecondary.Mui-selected{
    color: #2e7d32;
    background: rgba(46, 125, 50, 0.04);
  }

  #root .MuiTabs-flexContainer .MuiButtonBase-root.MuiTab-root{
    transition: .2s;
    min-height: max-content;
    border-radius: 3px;
    background: #21252907;
  }

  #root  .MuiTabs-scroller span.MuiTabs-indicator{
    background-color:#4AB276;
    bottom:7px;
  }